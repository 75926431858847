import LoadingSpinner from '../components/LoadingSpinner';
import { useBetsStore } from '../hooks/useBetsStore';

const MyAccount = () => {
  const loading = useBetsStore((state) => state.loading);
  const username = useBetsStore((state) => state.username);
  const setLoggedIn = useBetsStore((state) => state.setLoggedIn);

  const logout = () => {
    localStorage.removeItem('sessionToken');
    setLoggedIn(false);
    window.location.replace('/');
  };

  return (
    <div className="text-white mb-4 mt-4">
      <h1 className="mt-10 mb-10">NYRA PROFILE</h1>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <strong className="mt-5">USER: </strong> <span className="balance">{username}</span>
          <br />
          <button
            onClick={() => logout()}
            className="border-emerald-900 border-solid border-2 text-emerald-300 font-bold mt-20 squared p-2 hover:border-white hover:text-white">
            Log Out
          </button>
        </div>
      )}
    </div>
  );
};

export default MyAccount;
