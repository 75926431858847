import React, { useState } from 'react';
import { BetItemProps } from '../interfaces';
import { FaInfoCircle } from 'react-icons/fa';
import Alert from './Alert';

const BetItem: React.FC<BetItemProps> = ({ item }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  return (
    <>
      <div className="flex text-left mb-4 mt-4">
        <div className="w-5/6 p-1">{item.horseSelection}</div>

        {item.info.title !== 'N/A' && (
          <div className="w-1/6 p-1">
            <div id={`#tooltip${item.id}`} onClick={handleOpen}>
              <FaInfoCircle style={{ fontSize: '24px', marginLeft: '5px', cursor: 'pointer', display: 'inline' }} />
            </div>
          </div>
        )}
      </div>

      {item.info.title !== 'N/A' && <Alert title={item.info.title} description={item.info.description} isOpen={open} handleClose={handleOpen} />}
    </>
  );
};

export default BetItem;
