import PullToRefresh from 'react-simple-pull-to-refresh';
import RaceInfo from '../components/RaceInfo';
import BetList from '../components/BetList';
import { useEffect } from 'react';
import { refreshDataEventEmitter } from '../events';
import { useBetsStore } from '../hooks/useBetsStore';

const LiveBetting = () => {
  const bets = useBetsStore((state) => state.bets);
  const race = useBetsStore((state) => state.race);
  const fetchBets = useBetsStore((state) => state.fetchBets);
  const getBalance = useBetsStore((state) => state.getBalance);

  const refreshData = () => {
    return Promise.all([fetchBets(), getBalance()]);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshData();
    }, 60000);

    refreshDataEventEmitter.on('refreshData', refreshData);

    return () => {
      clearInterval(intervalId);
      refreshDataEventEmitter.off('refreshData', refreshData);
    };
  });

  return (
    <>
      <PullToRefresh onRefresh={refreshData}>
        <>
          <RaceInfo race={race} />
          <BetList bets={bets} race={race} />
        </>
      </PullToRefresh>
    </>
  );
};

export default LiveBetting;
