import { PlacedBetItemProps } from '../interfaces';

const PlacedBetItem = ({ bet }: PlacedBetItemProps) => {
  return (
    <>
      <div className="text-white mb-12 mt-4">
        <strong className="text-green-400">Ticket#:</strong> {bet.ticketSerialNumber}
        <br />
        <strong>{bet.suggestedBet.race.title}</strong> <br /> ${bet.suggestedBet.totalBetAmount} - {bet.createdAt.userFriendlyDate}
        <br />
        {bet.suggestedBet.horseSelection}
        <br />
        {bet.status === 'WIN' ? (
          <span className="inline-flex items-center rounded-md bg-green-400/10 px-2 py-1 text-xs font-medium text-green-500 ring-1 ring-inset ring-green-600/20">
            Win
          </span>
        ) : (
          ''
        )}
        {bet.status === 'LOST' ? (
          <span className="inline-flex items-center rounded-md bg-red-500/10 px-2 py-1 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-600/10">
            Lost
          </span>
        ) : (
          ''
        )}
        {bet.status === 'PLACED' || bet.status === 'PENDING' ? (
          <span className="inline-flex items-center rounded-md bg-yellow-900/10 px-2 py-1 text-xs font-medium text-yellow-500 ring-1 ring-inset ring-yellow-600/20">
            Pending
          </span>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default PlacedBetItem;
