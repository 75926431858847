import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';

interface AlertProps {
  title: string;
  description: string;
  isOpen: boolean;
  handleClose: () => void;
}

export default function Alert({ title, description, isOpen, handleClose }: AlertProps) {
  return (
    <Dialog open={isOpen} onClose={handleClose} className="relative z-10">
      <DialogBackdrop transition className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />

      <div className="fixed inset-0 z-10 flex items-center justify-center p-4">
        <DialogPanel
          transition
          className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-lg sm:max-w-md md:max-w-lg lg:max-w-xl">
          <div className="bg-white p-4 sm:p-6">
            <div className="text-center sm:text-left">
              <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                {title}
              </DialogTitle>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{description}</p>
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
