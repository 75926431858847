import React, { useEffect, useState } from 'react';
import './App.css';
import LoginWidget from './components/LoginWidget';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LiveBetting from './pages/LiveBetting';
import MyBets from './pages/MyBets';
import MyAccount from './pages/MyAccount';
import Header from './components/Header';
import RaceSchedule from './pages/RaceSchedule';
import SingleRace from './pages/SingleRace';
import { ToastContainer } from 'react-toastify';
import Video from './pages/Video';
import FairOdds from './pages/FairOdds';
import { useBetsStore } from './hooks/useBetsStore';
import ReactGA from 'react-ga4';

const App: React.FC = () => {
  const isSessionTokenInStorage = () => {
    const token = localStorage.getItem('sessionToken');
    if (token && token?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const TRACKING_ID = 'G-7FL2YV5M4T';
  ReactGA.initialize(TRACKING_ID);
  const initStore = useBetsStore((state) => state.initStore);
  const [loggedIn, setLoggedIn] = useState(isSessionTokenInStorage);
  const race = useBetsStore((state) => state.race);
  const refreshToken = useBetsStore((state) => state.refreshToken);

  const handleLogin = () => {
    localStorage.removeItem('selectedCard');
    setLoggedIn(true);
  };

  useEffect(() => {
    initStore();
    const refreshTokenInterval = setInterval(() => {
      refreshToken();
    }, 3600000);

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    return () => clearInterval(refreshTokenInterval);
  }, [initStore, refreshToken]);

  return (
    <>
      {loggedIn ? (
        <Router>
          <div className="app-container">
            <Header />
            <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <Routes>
              <Route path="/" element={<LiveBetting />} />
              <Route path="/my-bets" element={<MyBets />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/fair-odds" element={<FairOdds race={race} />} />
              <Route path="/race-schedule" element={<RaceSchedule />} />
              <Route path="/race/:raceId" element={<SingleRace />} />
              <Route path="/video" element={<Video />} />
            </Routes>
          </div>
        </Router>
      ) : (
        <LoginWidget onLogin={handleLogin} />
      )}
    </>
  );
};

export default App;
