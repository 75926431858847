import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import BetItem from './BetItem';
import { BetListProps, BetSuggestion } from '../interfaces';
import { HoldToConfirmButton } from './HoldToConfirmButton';
import LoadingSpinner from './LoadingSpinner';
import { currencyFormat } from './BalanceInfo';
import { useBetsStore } from '../hooks/useBetsStore';

const BetList: React.FC<BetListProps> = ({ bets, race }) => {
  const placeBet = useBetsStore((state) => state.placeBet);
  const loading = useBetsStore((state) => state.loading);

  const [emblaRef] = useEmblaCarousel();

  if (!race && !loading) {
    return <p className="text-white font-bold mt-20 pt-20 pb-80">Race schedule is empty, please try again later </p>;
  }

  if (race && bets.length === 0) {
    return (
      <p className="text-white font-bold mt-20 pt-20 pb-80">
        More waigrs will be generated for you
        <br />
        <br /> in a few minutes{' '}
      </p>
    );
  }

  return (
    <div className="embla !mt-40" ref={emblaRef}>
      <div className="embla__container">
        {bets.map((betSuggestion: BetSuggestion) => {
          const betIDs = betSuggestion.items.map((item: any) => item.id);
          const title = betSuggestion.items[0]?.title;

          return (
            <div
              key={betSuggestion.groupId}
              className="bet-item embla__slide p-4 m-12 relative"
              style={{ minHeight: betSuggestion.items.length > 1 ? '250px' : '210px' }}>
              {title && <h2 className="mb-2 mt-2 bet-item-title">{title}</h2>}

              {betSuggestion.items.map((item: any, index: number) => (
                <BetItem key={index} item={item} />
              ))}

              <div className="absolute bottom-4 left-0 w-full">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <HoldToConfirmButton onSubmit={() => placeBet(betIDs, betSuggestion.groupId)}>
                    Total: <span className="text-light-green">{currencyFormat(betSuggestion.total)}</span>
                  </HoldToConfirmButton>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BetList;
