import LoadingSpinner from '../components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import FairOddsTable from '../components/FairOddsTable';
import PlacedBets from '../components/PlacedBets';
import RaceInfo from '../components/RaceInfo';
import { useBetsStore } from '../hooks/useBetsStore';

const SingleRace = () => {
  const { raceId } = useParams();
  const loading = useBetsStore((state) => state.loading);
  const selectedRace = useBetsStore((state) => state.selectedRace);
  const getSingleRace = useBetsStore((state) => state.getSingleRace);
  const raceIdNumber = raceId ? parseInt(raceId, 10) : null;

  useEffect(() => {
    if (raceIdNumber) {
      getSingleRace(raceIdNumber);
    }
  }, [getSingleRace, raceIdNumber]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {selectedRace ? (
            <div className={'mb-10'}>
              <RaceInfo race={selectedRace} showSelectCardMenu={false} />
              <FairOddsTable race={selectedRace} />
              <PlacedBets bets={selectedRace.userBets} />
            </div>
          ) : (
            <p className="text-red-500 font-semibold">Race Details not available</p>
          )}
        </>
      )}
    </div>
  );
};

export default SingleRace;
