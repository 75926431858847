import LoadingSpinner from '../components/LoadingSpinner';
import PlacedBetItem from '../components/PlacedBetItem';
import { useBetsStore } from '../hooks/useBetsStore';

const Video = () => {
  const loading = useBetsStore((state) => state.loading);
  const race = useBetsStore((state) => state.race);

  if (!race) {
    return null;
  }

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {race.streaming.web ? (
            <div className="grid md:grid-cols-2 gap-4 content-between">
              <div>
                <iframe title="Race streaming" src={race.streaming.web} width="100%" height="480" allow="fullscreen"></iframe>
              </div>
              <div>
                <ul className="pb-64">
                  {race.userBets?.map((bet: any) => (
                    <li key={bet.id}>
                      <PlacedBetItem bet={bet} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div className="text-red-500 font-bold mt-12">
              <p>Race streaming is not available.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Video;
