import LoadingSpinner from '../components/LoadingSpinner';
import PlacedBetItem from '../components/PlacedBetItem';
import { useBetsStore } from '../hooks/useBetsStore';

const MyBets = () => {
  const loading = useBetsStore((state) => state.loading);
  const placedBets = useBetsStore((state) => state.placedBets);

  return (
    <div>
      <h1 className="text-white font-semibold mt-20">Latest Waigr Bets with Nyra</h1>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ul className="pb-64">
          {placedBets.map((bet) => (
            <li key={bet.id}>
              <PlacedBetItem bet={bet} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MyBets;
