import { axiosInstance as axios } from '../services/axiosInstance';
import { BetSuggestion, Race, BalanceInterface, RaceSchedule, Card } from '../interfaces';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { refreshDataEventEmitter } from '../events';
import { create } from 'zustand';
import ReactGA from 'react-ga4';

interface BetsState {
  race: Race | undefined;
  balance: BalanceInterface | undefined;
  bets: BetSuggestion[];
  loading: boolean;
  loggedIn: boolean;
  placedBets: any[];
  username: string;
  raceSchedule: RaceSchedule[];
  selectedRace: any | undefined;
  todayCards: Card[];
  hasFetchedData: boolean;
  initStore: () => Promise<void>;
  fetchBets: () => Promise<void>;
  getBalance: () => Promise<void>;
  getRaceSchedule: () => Promise<void>;
  getMyBets: () => Promise<void>;
  getTodayCards: () => Promise<void>;
  placeBet: (betIDs: Array<number>, groupId?: string) => Promise<void>;
  getSingleRace: (raceId: number) => Promise<void>;
  skipRace: (raceId: number) => Promise<void>;
  followRace: (raceId: number) => Promise<void>;
  setLoggedIn: (status: boolean) => void;
  setRaceSchedule: (raceSchedule: RaceSchedule[]) => void;
  refreshToken: () => Promise<void>;
}

export const useBetsStore = create<BetsState>((set, get) => ({
  race: undefined,
  balance: undefined,
  bets: [],
  loading: false,
  loggedIn: false,
  placedBets: [],
  username: '',
  raceSchedule: [],
  selectedRace: undefined,
  todayCards: [],
  hasFetchedData: false,

  initStore: async () => {
    if (!get().hasFetchedData) {
      set({ loading: true });
      try {
        await Promise.all([get().fetchBets(), get().getBalance(), get().getMyBets(), get().getRaceSchedule(), get().getTodayCards()]);
        set({ hasFetchedData: true });
      } finally {
        set({ loading: false });
      }
    }
  },

  fetchBets: async () => {
    try {
      let suggestedBetsUrl = 'api/v2/get-suggested-bets';
      if (localStorage.getItem('selectedCard') && localStorage.getItem('selectedCard') !== 'ALL') {
        suggestedBetsUrl += '?track=' + localStorage.getItem('selectedCard');
      }
      const response = await axios.get(suggestedBetsUrl);
      set({
        bets: response.data.data.bets,
        race: response.data.data.race,
        username: response.data.username,
      });
    } catch (error) {
      console.error('Error fetching bets:', error);
    }
  },

  getBalance: async () => {
    try {
      const response = await axios.get('api/get-balance');
      set({ balance: response.data });
    } catch (error: any) {
      console.error('Error fetching balance:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('sessionToken');
        get().setLoggedIn(false);
        window.location.replace('/');
      }
    }
  },

  getRaceSchedule: async () => {
    try {
      const response = await axios.get('api/v2/daily-schedule');
      set({ raceSchedule: response.data.data });
    } catch (error) {
      console.error('Error fetching daily schedule:', error);
    }
  },

  getMyBets: async () => {
    try {
      const response = await axios.get('api/placed-bets');
      set({ placedBets: response.data.data });
    } catch (error) {
      console.error('Error fetching placed bets:', error);
    }
  },

  getTodayCards: async () => {
    try {
      const response = await axios.get('api/today-tracks');
      set({ todayCards: response.data.data });
    } catch (error) {
      console.error("Error fetching today's cards:", error);
    }
  },

  placeBet: async (betIDs: Array<number>, groupId?: string) => {
    set({ loading: true });
    try {
      await axios.post('api/v2/place-bet', JSON.stringify({ suggested_bet_id: betIDs }));
      if (groupId) {
        const newSuggestedBets = get().bets.filter((bet: BetSuggestion) => bet.groupId !== groupId);
        set({ bets: newSuggestedBets });
      }
      toast.success("You're down!");
      refreshDataEventEmitter.emit('refreshData');
      ReactGA.event({ category: 'betting', action: 'placedBet' });
    } catch (error: any) {
      console.error('Error placing bet:', error);
      if (error.status === 514) {
        localStorage.removeItem('sessionToken');
        window.location.replace('/');
      }
    } finally {
      set({ loading: false });
    }
  },

  getSingleRace: async (raceId: number) => {
    set({ loading: true });
    try {
      const response = await axios.get(`api/v2/race/${raceId}`);
      set({ selectedRace: response.data?.data });
    } catch (error) {
      toast.error('Error getting race details');
    } finally {
      set({ loading: false });
    }
  },

  skipRace: async (raceId: number) => {
    set({ loading: true });
    try {
      await axios.patch(`api/v2/race/${raceId}/skip`);
      get().getRaceSchedule();
    } catch (error) {
      toast.error('Error skipping race:' + raceId);
    } finally {
      set({ loading: false });
    }
  },

  followRace: async (raceId: number) => {
    set({ loading: true });
    try {
      await axios.patch(`api/v2/race/${raceId}/follow`);
      get().getRaceSchedule();
    } catch (error) {
      toast.error('Error following race:' + raceId);
    } finally {
      set({ loading: false });
    }
  },

  setLoggedIn: (status: boolean) => set({ loggedIn: status }),
  setRaceSchedule: (raceSchedule: RaceSchedule[]) => set({ raceSchedule }),

  refreshToken: async () => {
    try {
      const response = await axios.get('api/refresh-token');
      if (response.data.success) {
        localStorage.setItem('sessionToken', response.data.data.newToken);
      } else {
        throw new Error('Server response invalid');
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      localStorage.removeItem('sessionToken');
      get().setLoggedIn(false);
      window.location.replace('/');
    }
  },
}));
