import { useState } from 'react';
import { Link } from 'react-router-dom';
import BalanceInfo from './BalanceInfo';
import { SlCalculator } from 'react-icons/sl';
import { FaListOl } from 'react-icons/fa';
import { HiOutlineCurrencyDollar } from 'react-icons/hi2';
import { FaRegUser } from 'react-icons/fa';
import { FiTarget } from 'react-icons/fi';
import { LuMenu } from 'react-icons/lu';
import { MdClose } from 'react-icons/md';
import { useBetsStore } from '../hooks/useBetsStore';

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const balance = useBetsStore((state) => state.balance);

  return (
    <div className="flex flex-row items-center justify-between py-8">
      <div className="md:basis-1/3 sm:basis-1/3 lg:basis-1/4">
        <a href="/">
          <img src="/logo512.png" alt="Waigr Logo" className="w-16 h-16 ml-4" />
        </a>
      </div>

      <div className="md:basis-1/3 sm:basis-1/3 lg:basis-1/4">
        <BalanceInfo balance={balance} />
      </div>

      <div className="md:basis-1/3 sm:basis-1/3 lg:basis-1/2 text-right">
        <nav>
          <section className="lg:hidden">
            <LuMenu className="mr-4 w-8 h-8 text-[#6cec48]" onClick={() => setIsNavOpen((prev) => !prev)} />

            <div
              className={`fixed top-0 right-0 w-full h-full bg-black z-50 opacity-80 font-semibold transform transition-transform duration-500 ease-in-out ${
                isNavOpen ? 'translate-x-0' : 'translate-x-full'
              }`}>
              <div className="absolute top-0 right-0 px-8 py-8" onClick={() => setIsNavOpen(false)}>
                <MdClose className="h-8 w-8 text-gray-600" />
              </div>
              <ul className="flex flex-col items-center justify-between min-h-[250px] mt-24">
                <Link to="/" onClick={() => setIsNavOpen(false)} className="my-8 text-[#6cec48] uppercase">
                  <FiTarget className="inline mr-2" /> Live Betting
                </Link>
                <Link to="/race-schedule" onClick={() => setIsNavOpen(false)} className="my-8 text-[#6cec48] uppercase">
                  <FaListOl className="inline mr-2" /> Race Schedule
                </Link>
                <Link to="/my-bets" onClick={() => setIsNavOpen(false)} className="my-8 text-[#6cec48] uppercase">
                  <HiOutlineCurrencyDollar className="inline mr-2" /> My Bets
                </Link>
                <Link to="/fair-odds" onClick={() => setIsNavOpen(false)} className="my-8 text-[#6cec48] uppercase">
                  <SlCalculator className="inline mr-2" /> Fair Odds
                </Link>
                <Link to="/my-account" onClick={() => setIsNavOpen(false)} className="my-8 text-[#6cec48] uppercase">
                  <FaRegUser className="inline mr-2" />
                  My Account
                </Link>
              </ul>
            </div>
          </section>

          <ul className="hidden space-x-8 lg:inline-flex text-white font-bold mr-6">
            <li>
              <a href="/">
                <FiTarget className="inline mr-2" /> Live Betting
              </a>
            </li>
            <li>
              <a href="/race-schedule">
                <FaListOl className="inline mr-2" /> Race Schedule
              </a>
            </li>
            <li>
              <a href="/my-bets">
                <HiOutlineCurrencyDollar className="inline mr-2" /> My Bets
              </a>
            </li>
            <li>
              <a href="/fair-odds">
                <SlCalculator className="inline mr-2" /> Fair Odds
              </a>
            </li>
            <li>
              <a href="/my-account">
                <FaRegUser className="inline mr-2" /> My Account
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
