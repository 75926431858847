import LoadingSpinner from '../components/LoadingSpinner';
import { useCallback, useEffect } from 'react';
import FairOddsTable from '../components/FairOddsTable';
import RaceInfo from '../components/RaceInfo';
import { FairOddsProps } from '../interfaces';
import { refreshDataEventEmitter } from '../events';
import { useBetsStore } from '../hooks/useBetsStore';

const FairOdds = ({ race }: FairOddsProps) => {
  const loading = useBetsStore((state) => state.loading);
  const fetchBets = useBetsStore((state) => state.fetchBets);
  const refreshData = useCallback(async () => {
    await fetchBets();
  }, [fetchBets]);

  useEffect(() => {
    refreshDataEventEmitter.on('refreshData', refreshData);
    return () => {
      refreshDataEventEmitter.off('refreshData', refreshData);
    };
  }, [refreshData]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className={'mb-10'}>
            <RaceInfo race={race} showSelectCardMenu={true} />
            <FairOddsTable race={race} />
          </div>
        </>
      )}
    </div>
  );
};

export default FairOdds;
