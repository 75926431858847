import React from 'react';

const CircleWithNumber = ({ number, mainColor, numberColor }: any) => {
  return (
    <div className={`flex items-center justify-center w-8 h-8 rounded-full`} style={{ background: mainColor, color: numberColor }}>
      {number}
    </div>
  );
};

export default CircleWithNumber;
